<template>
  <div>
    <v-alert
      v-if="displaySpeechCompatibilityError"
      type="error"
      class="compatibilityError"
      >{{ $t('speechNotSupported') }}</v-alert
    >
    <div v-else>
      <QuizShell
        :levels="levels"
        :selectedLevel="selectedLevel"
        i18n_instructionsKey="listenSpeakInstruction"
        :currentQuestionNumber="currentQuestionIndex + 1"
        :totalNumberOfQuestions="numberOfQuestions"
        :correctCount="correctCount"
        :wrongCount="wrongCount"
        :displaySubmit="false"
        :displayNextQuestionBtn="displayNextButton"
        :displayPreviousQuestionBtn="displayPreviousButton"
        :soundEffects="false"
        @onNextQuestion="goToNextQuestion"
        @onPreviousQuestion="goToPreviousQuestion"
        @onLevelSelected="restartWithNewLevel"
      >
        <template #question class="questionBox">
          <AudioPlayer
            hideButtonName="true"
            hideSettings="true"
            :speechText="currentQuestion?.question['gu']"
            :settings="audioSettings"
          />
          <v-card-title class="questionBox__text">
            {{ currentQuestion?.question['gu'] }}
          </v-card-title>
        </template>

        <template #answer>
          <div class="answerSlot__recording">
            <SpeechRecorder
              @onStart="startListening"
              @onEnd="stopListening"
              @onResult="onSpeechTextReceived"
              @onNotCompatible="displaySpeechCompatibilityError = true"
              @onReady="onSpeechRecorderReady"
              :reset="resetRecording"
            >
            </SpeechRecorder>
          </div>

          <div v-if="!recordingPerformed" class="answerSlot__recording-text">
            {{ speechText }}
          </div>
          <div v-else class="answerSlot__final-answer pa-1">
            <TextDiff
              :actualText="speechText"
              :expectedText="currentQuestion.question.gu"
              :textFullyReceived="recordingPerformed"
              :isMatchFound="isMatchFound"
            />
          </div>
        </template>
      </QuizShell>
    </div>
  </div>
</template>
<script>
import TextDiff from '../components/common/TextDiff.vue'
import SpeechRecorder from '../components/common/SpeechRecorder.vue'
import AudioPlayer from '../components/AudioPlayer.vue'
import QuizShell from '../components/common/QuizShell.vue'
import { mapGetters } from 'vuex'
import Utils from '../util/Utils'

export default {
  name: 'speech_to_text',
  props: ['onReceived', 'onFullyReceived', 'questionSubmitted', 'expectedText'],
  components: {
    TextDiff,
    QuizShell,
    AudioPlayer,
    SpeechRecorder
  },
  computed: {
    ...mapGetters(['listenSpeak']),

    levels() {
      return this.listenSpeak && Utils.getDefaultLevels(this.listenSpeak)
    },
    currentQuestion() {
      return (
        this.listenSpeak &&
        this.listenSpeak.filter((data) => data.level === this.selectedLevel)[
          this.currentQuestionIndex
        ]
      )
    },
    numberOfQuestions() {
      return this.listenSpeak.filter(
        (data) => data.level === this.selectedLevel
      ).length
    },
    displayNextButton() {
      return this.currentQuestionIndex < this.numberOfQuestions - 1
    },
    displayPreviousButton() {
      return this.currentQuestionIndex > 0
    }
  },

  data() {
    return {
      isMatchFound: false,
      soundBeingReceived: false,
      displaySpeechCompatibilityError: false,
      recognition: undefined,
      speechText: '',
      transcription_: [],
      recordingPerformed: false,
      currentQuestionIndex: 0,
      selectedLevel: '0',
      audioSettings: {
        selectedVoiceGender: 'FEMALE',
        selectedVoiceSpeedPreference: 0.5,
        muted: false
      },
      correctCount: 0,
      wrongCount: 0,
      currentQuestionScore: -1,
      stoppedListening: false,
      speechRecognitionAborter: null,
      resetRecording: false
    }
  },
  methods: {
    restartWithNewLevel(level) {
      this.selectedLevel = level
      this.currentQuestionIndex = 0
      this.wrongCount = 0
      this.correctCount = 0
      this.resetForNewQuestion()
    },
    resetForNewQuestion() {
      this.recordingPerformed = false
      this.speechText = ''
      this.currentQuestionScore = -1
      this.resetRecording = true
    },
    goToNextQuestion() {
      if (this.currentQuestionIndex < this.numberOfQuestions)
        this.currentQuestionIndex++
      this.resetForNewQuestion()
    },
    goToPreviousQuestion() {
      if (this.currentQuestionIndex > 0) this.currentQuestionIndex--
      this.resetForNewQuestion()
    },
    stopListening(speechText) {
      this.stoppedListening = true
      this.speechText = speechText.trim()
      this.recordingPerformed = true
      this.resetRecording = false

      this.checkAnswerAndSubmit()
    },
    startListening() {
      this.resetScore()
      this.recordingPerformed = false
      this.speechText = ''
    },
    onSpeechTextReceived(speechText) {
      console.log(speechText)
      this.recordingPerformed = false
      this.speechText = speechText.trim()
    },
    onSpeechRecorderReady() {
      console.log('recorder ready')
      this.resetRecording = false
    },
    checkAnswerAndSubmit() {
      let isMatchFound = false

      if (
        this.speechText ===
        this.currentQuestion.question.gu
          .substring(0, this.currentQuestion.question.gu.length - 1)
          .trim()
      ) {
        isMatchFound = true
      } else if (this.currentQuestion.answers.length > 0) {
        //check against all possible answers
        for (let answer of this.currentQuestion.answers) {
          console.log(
            `speechText: ${this.speechText} ::: checking against ${answer
              .substring(0, answer.length - 1)
              .trim()}`
          )
          if (
            this.speechText === answer.substring(0, answer.length - 1).trim()
          ) {
            console.log('match fround from alternate answer')
            isMatchFound = true
            break
          }
        }
      }
      console.log(`match found: ${isMatchFound}`)
      if (isMatchFound) {
        this.speechText = this.currentQuestion.question.gu
        this.currentQuestionScore = 1
      } else {
        this.currentQuestionScore = 0
      }
      this.updateScore()
      this.isMatchFound = isMatchFound
    },
    updateScore() {
      if (this.currentQuestionScore === 1) {
        this.correctCount++
      } else if (this.currentQuestionScore === 0) {
        this.wrongCount++
      }
    },
    resetScore() {
      if (this.currentQuestionScore === 1) {
        this.correctCount--
      } else if (this.currentQuestionScore === 0) {
        this.wrongCount--
      }
      this.currentQuestionScore = -1
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/global.scss';

.main {
  justify-content: center;
  text-align: center;
}
.compatibilityError {
  max-width: 98%;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  @include md_up {
    max-width: 50%;
  }
}
.questionBox {
  &__text {
    font-weight: 400;
    text-align: center;
    justify-content: center;
    word-break: break-word !important;

    @include sm-up {
      font-size: 30px;
      line-height: 45px;
    }
  }
}

.answerSlot {
  &__recording {
    margin-top: 10px;
  }
  &__microphone_animated {
    animation: fadeInOut 1.5s infinite;
  }
  &__recording-text {
    margin-top: 10px;
    color: black;
    font-weight: bold;
    font-size: large;
  }
  &__final-answer {
    color: black;
    font-weight: bold;
    font-size: large;
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 10px;
  }
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
</style>
