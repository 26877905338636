<template>
  <div>
    <v-row justify="center">
      <v-col cols="auto">
        <v-row justify="center" class="pa-5">
          <v-card shaped>
            <v-card-subtitle>
              <v-icon x-large :class="'ml-2 mr-2 ' + toTextStyle(isMatchFound)">{{ toAnswerIcon() }}</v-icon>
            </v-card-subtitle>
            <v-card-text>
              <span
                v-bind:key="index"
                v-for="(d, index) in diff"
                :class="textStyle(d)"
              >
                {{ d.value }}
              </span>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { diffChars } from 'diff'
export default {
  name: 'text-diff',
  props: ['isMatchFound', 'expectedText', 'actualText', 'textFullyReceived'],
  data() {
    return {
      diff: []
    }
  },
  created() {
    this.findDiff()
  },
  methods: {
    findDiff() {
      const result = diffChars(this.expectedText, this.actualText)
      this.diff = result.filter((d) => !d.removed)
    },
    textStyle(diff) {
      let result = 'highlight '
      if (!this.textFullyReceived) {
        return result
      }
      return this.toTextStyle(!diff.added)
    },
    toTextStyle(isMatchFound) {
      return 'highlight ' + (isMatchFound ? 'highlightGreen' : 'highlightRed')
    },
    toAnswerIcon() {
      return (this.isMatchFound ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline')
    }
  }
}
</script>
<style scoped>
.highlight {
  color: black;
  font-weight: bold;
  font-size: large;
}
.highlightGreen {
  color: green;
}
.highlightRed {
  color: red;
  display: inline-block;
}
.title {
  border: 1px solid black;
  padding: 5px;
}
</style>
